import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Row } from 'antd';

const TabContent = ({ tag }) => {
  const [data, setData] = useState([]);
  const defaultImage = 'https://copyright.bdstatic.com/vcg/creative/bb8233ed2591e86390aae2e9b78ceca6.jpg@wm_1,k_cGljX2JqaHdhdGVyLmpwZw==';

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`https://api.murphyyi.com/data/ai?type=${tag.key}`);
      if (result.data.code === 0) {
        setData(result.data.data);
      }
    };

    fetchData();
  }, [tag]);

  return (
    <div>
      <Row gutter={[16, 16]}>
        {data.map((item, index) => (
          <Col key={item.app_id} xs={24} sm={12} md={8} lg={6} xl={4} xxl={4 * 5 / data.length}>
            <a href={'https://'+item.app_link}>
              <Card
                hoverable
                style={{ height: '90%', marginBottom: '16px' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    alt={item.app_title}
                    src={item.app_logo}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage;
                    }}
                    style={{
                      width: '35px', // 减小图片大小
                      height: '35px', // 减小图片大小
                      objectFit: 'cover',
                      borderRadius: '80%',
                      marginRight: '8px'
                    }}
                  />
                  <div style={{
                    WebkitLineClamp: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    width: 'calc(100% - 32px)' // 防止标题被隐藏
                  }}>
                    {item.app_title}
                  </div>
                </div>
                <div style={{
                  WebkitLineClamp: 5,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  marginTop: '8px'
                }}>
                  {item.app_desc}
                </div>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TabContent;
