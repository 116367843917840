import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import MultiTabsPage from './pages/multiTabsPage';
// import Tabbed from './pages/tabContent';
import About from './pages/about';

const { Header, Content } = Layout;

function App() {
  return (
    <Router>
      <Layout>
        <Header>
          <Menu mode="horizontal" theme="dark" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">
              <Link to="/">首页</Link>
            </Menu.Item>
            {/* <Menu.Item key="2">
              <Link to="/about">关于我</Link>
            </Menu.Item> */}
            <Menu.Item key="3">
              <Link to="https://i.murphyyi.com">❤️墨菲易</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ padding: '20px' }}>
          <Routes>
            <Route index path="/" element={<MultiTabsPage />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
}

export default App;
