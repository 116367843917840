import React from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const About = () => {
  return (
    <div>
      <Title level={2}>关于我</Title>
      <Paragraph>
        这里是关于我的一些信息。您可以在此处添加个人简介、联系方式、兴趣爱好等内容。
      </Paragraph>
    </div>
  );
};

export default About;
