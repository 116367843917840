import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tabs,Grid } from 'antd';
import TabContent from './tabContent';
// import Navigation from './navigation';

const { TabPane } = Tabs;

const MultiTabsPage = () => {
  const [tags, setTags] = useState([]);
  
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const tabPosition = screens.xs ? 'top' : 'left';

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get('https://api.murphyyi.com/data/ai');
      if (result.data.code === 0) {
        setTags(result.data.data);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Tabs tabPosition={tabPosition} tabBarStyle={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}>
        {tags.map((tag) => (
          <TabPane
            tab={tag.tag}
            key={tag.key}
          >
            <TabContent tag={tag} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default MultiTabsPage;
